import React, { useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import "./devjoin.scss"
import styled, { ThemeProvider } from "styled-components"
import Button from "../components/Button"
import Logo from "../components/Logo/Logo"
import { rem } from "../utils/styling"
import { darkTheme } from "src/utils/themes"
import { useForm } from "react-hook-form"
import axios from "axios"
import { getDeveloperListAddress } from "../components/common/api"
import { splitName } from "src/utils/common"

const ModalTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(32)}rem;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: ${rem(32)}rem;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
  }
`

const ThankYouTitle = styled(ModalTitle)`
  text-align: center;
  @media screen and (min-width: 1100px) {
    text-align: left;
  }
`

const ThankYouText = styled.p`
  font-weight: 500;
  font-size: ${rem(16)}rem;
  line-height: 28px;
  text-align: center;
  margin-bottom: 44px;
  @media screen and (min-width: 1100px) {
    text-align: left;
    font-size: ${rem(20)}rem;
  }
`

const ErrorText = styled.span`
  color: red;
  font-size: 1rem;
  margin-top: 5px;
  display: inline-block;
`

const FullButton = styled(Button)`
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 40px;
`

function DevJoinPage({ location }) {
  const [showSuccess, setSuccess] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    validateCriteriaMode: "all",
  })

  const validateEmail = email => {
    // eslint-disable-next-line
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const updateMailChimp = formData => {
    const href = window.location.href
    const staging =
      href.includes("s.archimydes.dev") || href.includes("localhost")

    let [firstName, lastName] = splitName(formData.name)
    let payload = {
      email: formData.email,
      firstName,
      lastName,
      phone: formData.phonenumber || "",
      devRole: document.querySelector('input[name="role"]:checked').value,
      location: formData.location,
      training: false,
      environment: staging ? "Staging" : "Production",
    }
    setSuccess(true)
    axios
      .post(getDeveloperListAddress(), payload, {})
      .then(function (response) {
        setSuccess(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="subscription-page">
        <div className="page-wrapper">
          <div className="subscription-form">
            <div className="logo-wrapper">
              <SEO
                title="Request to join our Software Development Guild"
                description="Engage, Grow and Earn by joining our growing community of Developers."
                location={location}
              />
              <Logo />
            </div>
            {showSuccess && (
              <div className="subscription-form-content">
                <ThankYouTitle>Thank You</ThankYouTitle>
                <ThankYouText>
                  Your request has been received successfully. One of our Guild
                  members will get in touch with you shortly! <br /> <br />
                  Meanwhile, you can start interacting with members in the Guild
                  by joining our Discord Server
                </ThankYouText>
                <a href="https://discord.gg/zDdDSEbWgh" target="_blank">
                  <FullButton color="link">Join Discord</FullButton>
                </a>
                <Link to="/">
                  <FullButton type="link" color="secondary">
                    Back to site
                  </FullButton>
                </Link>
              </div>
            )}
            {!showSuccess && (
              <div className="subscription-form-content">
                <ModalTitle>
                  Request to join our Software Development Guild.
                </ModalTitle>
                <form onSubmit={handleSubmit(updateMailChimp)}>
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Your Name
                    </label>
                    <input
                      name="name"
                      placeholder="Full Name"
                      className="input-full"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Your Email
                    </label>
                    <input
                      name="email"
                      placeholder="Email"
                      className="input-full"
                      type="text"
                      ref={register({
                        validate: value => validateEmail(value),
                      })}
                    />
                    {errors.email && (
                      <ErrorText>Enter a valid email ID</ErrorText>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Your Location
                    </label>
                    <input
                      name="location"
                      placeholder="Location"
                      className="input-full"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.location && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                  </div>
                  <div className="form-group checkbox-group">
                    <legend className="input-label" htmlFor="">
                      What is your role as a developer?
                    </legend>
                    <div className="checkboxes" id="role">
                      <label className="checkbox" htmlFor="front-end-checkbox">
                        <input
                          placeholder="Email"
                          id="front-end-checkbox"
                          className="input-checkbox"
                          name="role"
                          type="radio"
                          value="Front-End"
                        />
                        <span className="checkmark"></span>
                        <span className="labelText">Front-End</span>
                      </label>

                      <label className="checkbox" htmlFor="back-end-checkbox">
                        <input
                          placeholder="Email"
                          id="back-end-checkbox"
                          className="input-checkbox"
                          name="role"
                          type="radio"
                          value="Back-End"
                        />
                        <span className="checkmark"></span>
                        <span className="labelText">Back-End</span>
                      </label>

                      <label className="checkbox" htmlFor="full-end-checkbox">
                        <input
                          placeholder="Email"
                          id="full-end-checkbox"
                          className="input-checkbox"
                          name="role"
                          type="radio"
                          value="Fullstack"
                        />
                        <span className="checkmark"></span>
                        <span className="labelText">Full Stack</span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <FullButton type="submit" color="secondary">
                      Submit
                    </FullButton>
                  </div>
                </form>
              </div>
            )}
          </div>

          <div className="subscription-image"></div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default DevJoinPage
